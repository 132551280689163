import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import DecenterlizedFullHeight from './webfullheight';
import DecenterlizedPad from './tabfullheight';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function DecenterlizedFull() {
  const classes = useStyles();
  const theme = useTheme();
  const isPad = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.root}>
      {
        /* {isMobile ? (
        <DecenterlizedMobile />
      ) : */ isPad ? <DecenterlizedPad /> : <DecenterlizedFullHeight />
      }
    </div>
  );
}
