import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: 'rgba(36, 36, 36, 0.75)',
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 5,
  },
  spinner: {
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    textAlign: 'center',
  },
  whiteSpin: {
    color: 'white',
  },
  title: {
    margin: '24px 0',
    color: 'white',
  },
}));

export default useStyles;
