import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject((error.response && error.response.data) || 'There is an error!'),
);

const API_KEY = process.env.REACT_APP_API_KEY;
const header = { headers: { 'x-api-key': API_KEY } };

export const listAPIKeys = () => {
  const userUUID = localStorage.getItem('userUUID');
  const data = {
    api_key: API_KEY,
    uuid: userUUID,
  };
  return API.post('api-key/listApiKeys', data);
};

export const createAPIKey = () => {
  const customerId = localStorage.getItem('customerId');
  const productCode = localStorage.getItem('productCode');
  const userUUID = localStorage.getItem('userUUID');

  const data = {
    customerId,
    productCode,
    uuid: userUUID,
    api_key: API_KEY,
  };
  return API.post('api-key/generate-additional-api-key/', data);
};

export const addAdmin = (uuid) => {
  const data = {
    api_key: API_KEY,
    uuid,
  };
  return API.post('addAsAdmin', data, header);
};

export const removeAdmin = (uuid) => {
  const data = {
    api_key: API_KEY,
    uuid,
  };
  return API.post('removeAdmin', data, header);
};

export const getSummary = () => {
  const userUUID = localStorage.getItem('userUUID');
  const data = {
    api_key: API_KEY,
    uuid: userUUID,
  };
  return API.post('getModalities', data, header);
};

export const linkNewAPIKey = (uuid) => {
  const apiKey = localStorage.getItem('apiKey');
  const data = {
    api_key: API_KEY,
    link_key: apiKey,
    uuid,
  };
  return API.post('api-key/linkApiKey', data, header);
};

export const checkAdmin = () => {
  const userUUID = localStorage.getItem('userUUID');
  const data = {
    api_key: API_KEY,
    uuid: userUUID,
  };
  return API.post('isAdmin', data, header);
};

export const generateAPIKey = ({ productCode, customerId }) =>
  API.post('api-key/generateApiKey', { productCode, customerId }, header);

export const checkSubscribeStatus = ({ productCode, customerId }) =>
  API.post('checkSubscribeStatus', { productCode, customerId }, header);

export const getAPISummary = (userApiKey) => {
  const data = { api_key: API_KEY, stats_api_key: userApiKey };
  return API.post('billingStats', data, header);
};

export const requestWaitlist = (email) => {
  const data = { email, message: 'Waitlist request' };
  return API.post('contactUs', data, header);
};

export const submitConversation = ({ name, email, phone, message }) => {
  const data = { name, email, phone, message };
  return API.post('contactUs', data, header);
};
