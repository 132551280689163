import React, { useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Button, Typography, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { submitConversation } from '../../api';
import background from '../../assets/letstalkback.png';
import img from '../../assets/talkimg.png';
import './styles.css';
import 'react-phone-number-input/style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundImage: `url(${background})`,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundSize: '100% 100%',
  },
  main: {
    maxWidth: 1920,
    [theme.breakpoints.down('sm')]: {
      height: 600,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  textbox: {
    // [theme.breakpoints.up("xl")]: {
    //   maxWidth: 500,
    // },
  },
  heading: {
    width: 201,
    height: 53,
    fontFamily: 'Axiforma',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 40,
    lineHeight: '131.5%',
    /* identical to box height, or 53px */
    marginTop: 40,
    color: '#FFFFFF',
    [theme.breakpoints.up('xl')]: {
      fontSize: 48,
      height: 73,
      width: 500,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      height: 33,
      marginTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  text: {
    width: 233,
    height: 58,
    fontFamily: 'Axiforma',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 22,
    lineHeight: '131.5%',
    /* or 29px */

    color: '#FFFFFF',
    [theme.breakpoints.up('xl')]: {
      fontSize: 34,
      height: 60,
      width: 500,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      width: 210,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  img: {
    height: 184,
    [theme.breakpoints.up('xl')]: {
      height: 250,
    },
    [theme.breakpoints.down('md')]: {
      width: 180,
      height: 130,
    },
    [theme.breakpoints.down('sm')]: {
      width: 310,
      height: 208,
      marginTop: '-25rem',
      marginLeft: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0rem',
      width: 200,
      height: 144,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 374,
    height: 388.59,
    background: '#FFFFFF',
    borderRadius: 14,
    marginLeft: '1rem',
    [theme.breakpoints.up('xl')]: {
      height: 450,
      width: 450,
    },
    [theme.breakpoints.only('md')]: {
      width: 327,
      height: 331,
    },
    [theme.breakpoints.only('sm')]: {
      position: 'absolute',
      width: 488,
      height: 331,
      marginTop: '3rem',
      marginLeft: '-19rem',
    },
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      width: '85%',
      height: 331,
      marginTop: '3rem',
      marginLeft: '-12.2rem',
    },
  },
  inputbox: {
    height: 60,
    [theme.breakpoints.up('xl')]: {
      height: 70,
    },
    [theme.breakpoints.down('md')]: {
      height: 50,
    },
    [theme.breakpoints.only('xs')]: {
      width: '80%',
    },
  },
  msginput: {
    [theme.breakpoints.only('xs')]: {
      width: '80%',
    },
  },
  input: {
    width: 326,
    height: 38,
    outline: 0,
    background: '#FFFFFF',
    border: '1px solid #C9C9C9',
    boxSizing: 'border - box',
    boxShadow: '0px 15px 9px -6px rgba(199, 199, 199, 0.25)',
    borderRadius: 8,
    fontFamily: 'Axiforma',
    paddingLeft: 10,
    [theme.breakpoints.up('xl')]: {
      width: 400,
      height: 50,
    },
    [theme.breakpoints.only('md')]: {
      width: 285,
      height: 33,
    },
    [theme.breakpoints.only('sm')]: {
      width: 425,
      height: 33,
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: 33,
    },
  },

  msg: {
    width: 326,
    height: 103,
    outline: 0,
    background: '#FFFFFF',
    border: '1px solid #C9C9C9',
    boxSizing: 'border - box',
    fontFamily: 'Axiforma',
    boxShadow: '0px 15px 9px -6px rgba(199, 199, 199, 0.25)',
    borderRadius: 8,
    paddingLeft: 10,
    [theme.breakpoints.up('xl')]: {
      width: 400,
      height: 118,
    },
    [theme.breakpoints.only('md')]: {
      width: 285,
      height: 93,
    },
    [theme.breakpoints.only('sm')]: {
      width: 425,
      height: 93,
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: 93,
    },
  },
  btn: {
    width: 148,
    height: 37,
    background: '#383838',
    borderRadius: 8,
    fontFamily: 'Axiforma',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 18,
    lineHeight: '120%',
    /* or 22px */
    marginTop: 20,
    color: '#FFFFFF',
    [theme.breakpoints.up('xl')]: {
      width: 180,
      height: 47,
      fontSize: 20,
    },
    '&:hover': {
      background: '#383838',
    },
  },
  leftgrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  rightgrid: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  error: {
    color: 'red',
    fontFamily: 'Axiforma',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 10,
    textAlign: 'left',
    paddingLeft: 4,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().min(3, 'Name must have minimum three characters').required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email address is required'),
  phone: Yup.string().required('Phone number is required'),
  message: Yup.string().required('Please input message contents'),
});

export default function LetsTalkWeb() {
  const [snackOpened, setSnackOpen] = useState(false);
  const classes = useStyles();
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  const handleSubmit = async (values, { setErrors, setSubmitting }) => {
    try {
      if (!isValidPhoneNumber(values.phone)) {
        setErrors({ phone: 'Invalid phone number' });
        return;
      }
      const { status } = await submitConversation(values);
      if (status === 0) setSnackOpen(true);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          setTouched,
          setErrors,
          isSubmitting,
        }) => {
          const handleClickSubmit = (event) => {
            event.preventDefault();
            setTouched({});
            setErrors({});
            handleSubmit();
          };

          const handleChangePhone = (value) => setFieldValue('phone', value);

          return (
            <div className={classes.root}>
              <Box className={classes.textbox}>
                <Box className={classes.heading}>Let&apos;s Talk</Box>
                <Box className={classes.text}>Start a conversation.</Box>
              </Box>
              <Grid container className={classes.main}>
                <Grid item lg={6} md={6} className={classes.leftgrid}>
                  <img src={img} className={classes.img} alt="" />
                </Grid>
                <Grid item lg={5} md={6} className={classes.rightgrid}>
                  <form noValidate className={classes.form} onSubmit={handleClickSubmit}>
                    <Box className={classes.inputbox}>
                      <input
                        type="text"
                        name="name"
                        className={classes.input}
                        placeholder="Name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      {!!touched.name && !!errors.name && (
                        <Typography className={classes.error}>{errors.name}</Typography>
                      )}
                    </Box>
                    <Box className={classes.inputbox}>
                      <input
                        type="email"
                        className={classes.input}
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {!!touched.email && !!errors.email && (
                        <Typography className={classes.error}>{errors.email}</Typography>
                      )}
                    </Box>
                    <Box className={classes.inputbox}>
                      <PhoneInput
                        defaultCountry="US"
                        placeholder="Telephone"
                        className={classes.input}
                        onChange={handleChangePhone}
                        name="phone"
                        value={values.phone}
                      />
                      {!!touched.phone && !!errors.phone && (
                        <Typography className={classes.error}>{errors.phone}</Typography>
                      )}
                    </Box>
                    <Box className={classes.msginput}>
                      <textarea
                        type="text"
                        placeholder="Message"
                        name="message"
                        className={classes.msg}
                        value={values.message}
                        onChange={handleChange}
                      />
                      {!!touched.message && !!errors.message && (
                        <Typography className={classes.error}>{errors.message}</Typography>
                      )}
                    </Box>
                    <Button type="submit" className={classes.btn} disabled={isSubmitting}>
                      {isSubmitting ? <CircularProgress size="1rem" /> : 'SEND'}
                    </Button>
                  </form>
                </Grid>
              </Grid>
            </div>
          );
        }}
      </Formik>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackOpened}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          Thanks for contacting us. We will touch in you soon.
        </Alert>
      </Snackbar>
    </>
  );
}
