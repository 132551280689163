import { Button, Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import useStyles from './style';
import LogoImg from '../../assets/logo_1.png';

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  // should be from auth
  const isAuthenticated = localStorage.getItem('authenticated') === 'true';

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <Box className={classes.header} display="flex" alignItems="center">
      <Box className={classes.logoBox} display="flex" alignItems="center">
        <img className={classes.logo} src={LogoImg} alt="" />
      </Box>
      <Box display="flex" alignItems="center" className={classes.buttons}>
        {isAuthenticated && (
          <Button color="primary" variant="contained" onClick={() => handleLogout()}>
            Logout
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Header;
