import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 120,
  },
  cardImg: {
    height: 150,
  },
  cardContent: {
    display: 'flex',
    flex: 1,
  },
  iconButton: {
    backgroundColor: 'rgba(110, 117, 159, 0.1)',
    color: '#5569ff',
    width: 64,
    height: 64,
    borderRadius: 100,
    marginLeft: 24,
    '&:hover': {
      backgroundColor: 'rgba(153, 158, 187, 0.3)',
    },
  },
  createKey: {
    justifyContent: 'center',
    height: 60,
  },
  tableContinaer: {
    marginTop: 24,
    width: 320,
    maxHeight: 240,
    overflowY: 'overlay',
    boxShadow: '0px 2px 1px 2px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
}));

export default useStyles;
