import logo from '../../assets/logo.png';
import './styles.css';

const LogoHeader = () => (
  <div className="image-container">
    <img src={logo} alt="PrivateId Logo" />
  </div>
);

export default LogoHeader;
