import PropTypes from 'prop-types';
import './styles.css';

const Progress = ({ progress }) => (
  <div className="progress-container" style={{ height: window.innerHeight }}>
    <div className="progress-bar" style={{ width: `${progress}%` }} />
  </div>
);

Progress.propTypes = {
  progress: PropTypes.number,
};

export default Progress;
