/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import LoadingSpinner from '../../components/LoadingSpinner';
import APIKeyIcon from '../../assets/api-key.png';
import useStyles from './style';

const StyledCard = withStyles({
  root: {
    padding: '12px 12px 0',
    boxShadow: '0px 2px 4px 4px rgb(159 162 191 / 18%), 0px 2px 6px 2px rgb(159 162 191 / 32%);',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})(Card);

const YourAPIKeys = (userData) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading] = useState(false);
  const [key, setKey] = useState('');
  const [showKey, setShowKey] = useState('View Key and copy to clipboard');
  const showApiKey = () => {
    setShowKey(key);
    navigator.clipboard.writeText(key);
    enqueueSnackbar('Copied to clipboard', { variant: 'success' });
  };
  useEffect(() => {
    setKey(userData.userApiKey);
  }, [userData.userApiKey]);

  return (
    <StyledCard>
      <CardContent className={classes.cardContent}>
        <Box flex="1 0 auto">
          <Typography component="h5" variant="h5">
            Your API Key
          </Typography>
          <br />
          <Button color="primary" variant="contained" size="large" onClick={showApiKey} style={{ margin: '0 5px' }}>
            {showKey}
          </Button>
        </Box>
        <Box>
          <img className={classes.cardImg} src={APIKeyIcon} alt="" />
        </Box>
      </CardContent>
      {loading && <LoadingSpinner />}
    </StyledCard>
  );
};

export default YourAPIKeys;
