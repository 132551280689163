import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import FooterFields from './upper';
import FooterAccordion from './accodian';

const useStyles = makeStyles(() => ({
  root: { height: '100%' },
}));

export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <div className={classes.root}>{isMobile ? <FooterAccordion /> : <FooterFields />}</div>;
}
