import { useState } from 'react';
import { predict1FA } from '@privateid/cryptonets-web-sdk';
import useWasm from './useWasm';

const usePredict = (element = 'userVideo', onSuccess, onFailure, onNotFound, retryTimes = 4) => {
  const { ready: wasmReady } = useWasm();
  const [faceDetected, setFaceDetected] = useState(false);
  const [predictResultData, setPredictResultData] = useState(null);
  let successCallback = null;
  let tries = 0;
  let failureTries = 0;

  const predictUser = async (onSuccessCallback) => {
    if (wasmReady) {
      if (onSuccessCallback) {
        successCallback = onSuccessCallback;
      }
      await predict1FA(
        callback,
        {
          input_image_format: 'rgba',
        },
        element,
      );
    }
  };

  const callback = async (result) => {
    switch (result.status) {
      case 'VALID_FACE':
        setFaceDetected(true);
        break;
      case 'INVALID_FACE':
        if (failureTries === retryTimes) {
          onNotFound();
        } else {
          failureTries += 1;
        }
        break;
      case 'WASM_RESPONSE':
      case -1:
      case -100:
        if (result.returnValue.status === 0) {
          setPredictResultData(result.returnValue.PI.guid, result.returnValue.PI.uuid);
          if (successCallback) {
            successCallback(result.returnValue.PI.guid, result.returnValue.PI.uuid);
          } else {
            onSuccess(result.returnValue.PI.guid, result.returnValue.PI.uuid);
          }
          successCallback = null;
        }
        if (result.returnValue.status === -1) {
          if (tries === retryTimes) {
            onFailure();
          } else {
            tries += 1;
            await predictUser();
          }
        }
        break;
      default:
    }
  };

  return { wasmReady, faceDetected, predictUser, predictResultData };
};

export default usePredict;
