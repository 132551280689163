import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  InputLabel,
  Card,
} from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
} from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import KeyTwoToneIcon from '@material-ui/icons/VpnKeyTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { wait } from '../../utils';
import LoadingSpinner from '../../components/LoadingSpinner';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { checkSubscribeStatus, generateAPIKey } from '../../api';
import useStyles from './style';

const StyledTimelineItem = withStyles({
  missingOppositeContent: {
    '&:before': {
      flex: 0,
    },
  },
})(TimelineItem);

const Subscribe = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [copied, setCopied] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [apiKey, setAPIKey] = useState('');
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const loading = useCallback(async () => {
    const productCode = params.get('productCode');
    const customerId = params.get('customerId');
    localStorage.clear();
    localStorage.setItem('productCode', productCode);
    localStorage.setItem('customerId', customerId);
    const intervalId = setInterval(() => {
      checkSubscribeStatus({ productCode, customerId }).then((result) => {
        const { status } = result;
        if (status === 0) {
          clearInterval(intervalId);
          generateAPIKey({ productCode, customerId }).then((result) => {
            const { api_key, message, status } = result;
            if (!api_key) {
              setLoading(false);
              enqueueSnackbar(message, { variant: 'error' });
            } else if (status === 2) {
              setAPIKey(api_key);
              setLoading(false);
              localStorage.setItem('apiKey', api_key);
              enqueueSnackbar('You already subscribed.', { variant: 'warning' });
            } else {
              wait(10000).then(() => {
                setLoading(false);
                enqueueSnackbar(message, { variant: 'success' });
                localStorage.setItem('apiKey', api_key);
                setAPIKey(api_key);
              });
            }
          });
        }
      });
    }, 5000);
  }, [enqueueSnackbar, params]);

  useEffect(() => {
    loading();
  }, [loading]);

  return (
    <Box>
      <Header />
      <Container className={classes.root}>
        <Card className={classes.card}>
          <Box className={classes.title}>
            <Typography variant="h4" className={classes.header}>
              Welcome to Private Identity
            </Typography>
            <Typography variant="subtitle1" color="primary" className={classes.subHeader}>
              Do not leave this page until you create an account
            </Typography>
          </Box>
          <Timeline>
            <StyledTimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <KeyTwoToneIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6">STEP 1: GET YOUR API KEY</Typography>
                <Box className={classes.keyForm} display="flex" alignItems="center">
                  <InputLabel className={classes.label}>API Key: </InputLabel>
                  <TextField value={apiKey} variant="outlined" fullWidth />
                  <CopyToClipboard text={apiKey} onCopy={() => setCopied(true)}>
                    <Tooltip arrow open={copied} title="Copied" onClose={() => setCopied(false)} leaveDelay={1500}>
                      <IconButton className={classes.iconBtn} size="small" color="primary">
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                </Box>
              </TimelineContent>
            </StyledTimelineItem>
            <StyledTimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <AccountCircleTwoToneIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6">STEP 2: ENROLL TO MANAGE YOUR API KEY</Typography>
                <Box className={classes.keyForm} display="flex" alignItems="center">
                  <InputLabel className={classes.label}>Enroll: </InputLabel>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => navigate('/enroll', { state: { subscribe: true } })}
                  >
                    Create an account
                  </Button>
                </Box>
              </TimelineContent>
            </StyledTimelineItem>
          </Timeline>
          {isLoading && <LoadingSpinner isBuild />}
        </Card>
      </Container>
      <Footer />
    </Box>
  );
};

export default Subscribe;
