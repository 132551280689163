import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import useStyles from './style';

const LoadingSpinner = ({ isBuild }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      {isBuild ? (
        <Box className={classes.spinner}>
          <CircularProgress className={classes.whiteSpin} />
          <Typography variant="h5" className={classes.title}>
            BUILDING YOUR ACCOUNT...
          </Typography>
          <Typography className={classes.whiteSpin}>This may take a few minutes.</Typography>
          <Typography className={classes.whiteSpin}>Please do not leave this screen.</Typography>
        </Box>
      ) : (
        <CircularProgress className={classes.spinner} />
      )}
    </Box>
  );
};

LoadingSpinner.propTypes = {
  isBuild: PropTypes.bool,
};

export default LoadingSpinner;
