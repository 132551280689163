import { Routes, Route } from 'react-router-dom';

import './App.css';

import Home from './pages/Home';
import Subscribe from './pages/Subscribe';
import Enroll from './pages/Enroll';
import Manage from './pages/Manage';

const App = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/subscribe" element={<Subscribe />} />
    <Route path="/enroll" element={<Enroll />} />
    <Route path="/manage" element={<Manage />} />
  </Routes>
);

export default App;
