import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import { openCamera } from '@privateid/cryptonets-web-sdk';

import LogoImg from '../../assets/logo_1.png';
import LogoImgSm from '../../assets/logo_sm.png';
import GitLogo from '../../assets/github_logo.png';
import GitLogoSm from '../../assets/github_logo_sm.png';
import LoginImg from '../../assets/signin_privid.png';
import usePredict from '../../hooks/usePredict';
import HintModal from '../hintModal';
import ConsentModal from '../consentModal';
import './style.css';

const HeaderBar = () => {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [consentIsOpen, setConsentIsOpen] = useState(false);
  const [mediaStream, setStream] = useState(null);

  const onFailure = () => {
    setIsOpen(false);
    setConsentIsOpen(true);
  };

  const onSuccess = (guid, uuid) => {
    localStorage.setItem('userUUID', uuid);
    localStorage.setItem('authenticated', true);
    navigate('/manage');
  };

  const onNotFound = () => {
    setStream(null);
    setIsOpen(true);
  };

  const agreeOnTerms = () => {
    navigate('/enroll', { state: { consent: true } });
  };

  const disagreeOnTerms = () => {
    setConsentIsOpen(false);
  };

  const startPredict = async () => {
    if (!mediaStream) {
      const camera = await openCamera('userVideo');
      setStream(camera.stream);
    }
  };

  useEffect(() => {
    predictUser();
  }, [mediaStream]);

  const { wasmReady, predictUser } = usePredict('userVideo', onSuccess, onFailure, onNotFound);

  return (
    <div className="header-bar-container">
      <Hidden xsDown>
        <img
          className="logoImg"
          src={LogoImg}
          alt="logo"
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
        />
      </Hidden>
      <Hidden smUp>
        <img
          className="logoImg"
          src={LogoImgSm}
          alt="logo"
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
        />
      </Hidden>
      <div>
        <a href="https://github.com/prividentity/CryptoNets/wiki" target="_blank" rel="noopener noreferrer">
          <Hidden xsDown>
            <img className="git-wiki" src={GitLogo} alt="github" />
          </Hidden>
          <Hidden smUp>
            <img className="git-wiki" src={GitLogoSm} alt="github" />
          </Hidden>
        </a>
        {wasmReady && <img className="logoImg" src={LoginImg} alt="LoginImg" onClick={startPredict} />}
      </div>
      {!modalIsOpen && <video muted autoPlay playsInline style={{ display: 'none' }} id="userVideo" />}
      {modalIsOpen && (
        <HintModal setIsOpen={setIsOpen} predictUser={predictUser} message="Look on the camera to login" />
      )}
      <ConsentModal modalIsOpen={consentIsOpen} agreeOnTerms={agreeOnTerms} disagreeOnTerms={disagreeOnTerms} />
    </div>
  );
};

export default HeaderBar;
