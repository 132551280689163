import { Avatar } from '@material-ui/core';
import { getSeatId } from '../../utils';

const WelcomeHeader = () => {
  const userUUID = localStorage.getItem('userUUID');
  const seatId = getSeatId(userUUID);

  return (
    <div
      style={{
        height: '10vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '70px',
        marginTop: '50px',
      }}
    >
      <Avatar style={{ width: '100px', height: '100px' }} />
      <div style={{ fontSize: '24px', fontWeight: '400', marginTop: '10px' }}>Welcome {seatId}</div>
      <p>{userUUID}</p>
    </div>
  );
};

export default WelcomeHeader;
