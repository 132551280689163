import { Box, Container, Divider, Grid, Typography } from '@material-ui/core';

import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Header from '../../components/header';
import WelcomeHeader from '../../components/WelcomeHeader';
import YourAPIKeys from './YourAPIKeys';
import APISummary from './APISummary';
import DeleteAccount from './DeleteAccount';
import useStyles from './style';
import { checkAdmin, listAPIKeys } from '../../api';
import LoadingSpinner from '../../components/LoadingSpinner';

const APIAdminDashboard = (userData) => (
  <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
    <Grid item xs={12}>
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        API Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <YourAPIKeys userApiKey={userData.userData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <APISummary userApiKey={userData.userData} />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const Manage = () => {
  const classes = useStyles();
  const [admin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userApiKey, setUserApiKey] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const checkAdminData = useCallback(async () => {
    try {
      setLoading(true);
      const { status, admin } = await checkAdmin();
      if (status === 0 && admin) {
        setAdmin(admin);
        const { api_key } = await listAPIKeys();
        setUserApiKey(api_key);
        localStorage.setItem('apiKey', api_key);
      }
    } catch (error) {
      enqueueSnackbar('Failed to load API summary', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    checkAdminData();
  }, [checkAdminData]);

  return (
    <Box>
      <Header />
      <Container maxWidth="lg" className={classes.root}>
        <WelcomeHeader />
        {admin && userApiKey && <APIAdminDashboard userData={userApiKey} />}
        <Divider style={{ marginBottom: '50px' }} />
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" style={{ marginBottom: 24 }}>
              Manage Your Private Identity®
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DeleteAccount />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {loading && <LoadingSpinner />}
    </Box>
  );
};

export default Manage;
