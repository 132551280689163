import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  header: {
    height: 88,
    color: '#6E759F',
    zIndex: 3,
    backgroundColor: '#fff',
    boxShadow: '0px 2px 3px rgba(159, 162, 191, 0.18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
    justifyContent: 'space-between',
    width: '100%',
    position: 'fixed',
  },
  logoBox: {
    paddingLeft: 24,
  },
  logo: {
    height: 40,
    width: 'auto',
  },
  loginImg: {
    height: 38,
    width: 'auto',
  },
  buttons: {
    paddingRight: 24,
  },
}));

export default useStyles;
