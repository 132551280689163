import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import LetsTalkMobile from './mobile';
import LetsTalkWeb from './web';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function LetsTak() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <div className={classes.root}>{isMobile ? <LetsTalkMobile /> : <LetsTalkWeb />}</div>;
}
