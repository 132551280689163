/* eslint-disable camelcase */
import { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import { getAPISummary } from '../../api';
import LoadingSpinner from '../../components/LoadingSpinner';
import SummaryImg from '../../assets/api-summary.png';

import useStyles from './style';

const StyledCard = withStyles({
  root: {
    padding: '12px 12px 0',
    boxShadow: '0px 2px 4px 4px rgb(159 162 191 / 18%), 0px 2px 6px 2px rgb(159 162 191 / 32%);',
    height: '100%',
  },
})(Card);

const APISummary = (userData) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [apiKey] = useState(userData.userApiKey || null);
  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const { billing_stats } = await getAPISummary(apiKey);
      setData(billing_stats);
    } catch (error) {
      enqueueSnackbar('Failed to load API summary', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [apiKey, enqueueSnackbar]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <StyledCard>
      <CardContent className={classes.cardContent}>
        <Box flex="1 0 auto">
          <Typography component="h5" variant="h5">
            API Key Activity Summary
          </Typography>
          {data && data.length && (
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Requests</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data[0].map((stat) => (
                    <TableRow key={stat}>
                      <TableCell component="th" scope="row">
                        {stat[0]}
                      </TableCell>
                      <TableCell>{stat[1][0]}</TableCell>
                      <TableCell>{stat[1][1]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <Box>
          <img className={classes.cardImg} src={SummaryImg} alt="" />
        </Box>
      </CardContent>
      {loading && <LoadingSpinner />}
    </StyledCard>
  );
};

export default APISummary;
