import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import EngineeredTab from './tab';
import EngineeredWeb from './web';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Engineered() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return <div className={classes.root}>{isMobile ? <EngineeredTab /> : <EngineeredWeb />}</div>;
}
