/* eslint-disable */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { clearDB, enroll1FA } from '@privateid/cryptonets-web-sdk';
import Webcam from 'react-webcam';

import useWasm from '../../hooks/useWasm';

import { linkNewAPIKey } from '../../api';
import LogoHeader from '../../components/LogoHeader';
import ConsentModal from '../../components/consentModal';
import { videoConstraints } from '../../constants';
import { getDisplayedMessage } from '../../utils';
import Progress from '../../components/Progress';
import './styles.css';
import useCamera from '../../hooks/useCamera';

const Enroll = () => {
  const { ready: wasmReady } = useWasm();
  const { ready, init, device, devices, faceMode, setDevice } = useCamera('userVideo');
  const navigate = useNavigate();
  const { state } = useLocation();

  const [displayedStatus, setStatus] = useState('Ready');
  const [progress, setProgress] = useState(0);
  const [agree, setAgree] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);

  function agreeOnTerms() {
    setAgree(true);
    setIsOpen(false);
  }

  function disagreeOnTerms() {
    navigate(-1);
  }

  useEffect(() => {
    if (wasmReady) {
      // clearDB();
      setIsOpen(!state?.consent);
      setAgree(!!state?.consent);
    }
  }, [wasmReady]);

  useEffect(() => {
    if (!wasmReady) return;
    if (!ready) init();
  }, [wasmReady, ready]);

  useEffect(() => {
    if (!wasmReady || !agree || !ready) return;
    enroll1FA(callback, { input_image_format: 'rgba' });
  }, [wasmReady, agree, ready]);

  const callback = async (result) => {
    console.log('___________enroll result:', result);
    switch (result.status) {
      case 'ERROR_ENROLL':
        setStatus('');
        break;
      case 'ENROLLING':
        setStatus('Enrolling');
        break;
      case 'VALID_FACE':
        setStatus('');
        setProgress(result.progress);
        break;
      case 'INVALID_FACE':
        {
          const displayedText = getDisplayedMessage(result.result);
          setStatus(displayedText);
        }
        break;
      case 'WASM_RESPONSE':
        if (result.returnValue.status === 0) {
          console.log('what is STATE?', state);
          if (state?.subscribe) {
            const { uuid } = result.returnValue.PI;
            localStorage.setItem('userUUID', uuid);
            if (!localStorage.getItem('apiKey')) {
              navigate(-1);
            }
            await linkNewAPIKey(uuid);
            localStorage.setItem('authenticated', true);
            navigate('/manage');
          } else {
            const { uuid } = result.returnValue.PI;
            localStorage.setItem('userUUID', uuid);
            navigate('/manage');
          }
        }
        break;
      default:
        break;
    }
  };

  if (!wasmReady) {
    return (
      <div className="container" style={{ height: window.innerHeight }}>
        <div className="loader" />
      </div>
    );
  }

  return (
    <div id="canvasInput" className="container ready" style={{ height: window.innerHeight }}>
      <LogoHeader />
      <div>
        <ConsentModal modalIsOpen={modalIsOpen} disagreeOnTerms={disagreeOnTerms} agreeOnTerms={agreeOnTerms} />
      </div>

      <Webcam
        muted
        audio={false}
        className={`face ${displayedStatus === 'Enrolling' ? 'enroll' : ''}`}
        height={1280}
        width={720}
        id="userVideo"
        videoConstraints={videoConstraints}
        autoPlay
        playsInline
        mirrored
      />
      {displayedStatus === 'Enrolling' && <div className="loader" />}

      <div className="enroll-text-info">
        <p>{displayedStatus === '' ? <Progress progress={progress} /> : displayedStatus}</p>
      </div>
    </div>
  );
};
export default Enroll;
