import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';

import cancelIcon from '../../assets/cancel.svg';
import validIcon from '../../assets/checkmark.svg';

const ConsentModal = ({ modalIsOpen, disagreeOnTerms, agreeOnTerms }) => (
  <Modal className="modal" overlayClassName="overlay" open={modalIsOpen} onClose={disagreeOnTerms}>
    <div className="modal-container">
      <div className="modal-content">
        <h3>CONSENT TO ENROLL</h3>
        <p>
          This Private ID® app protects your privacy by immediately transforming your face and voice biometrics into
          anonymized data in compliance with IEEE 2410 Standard for Biometric Privacy. No biometric data, biometric
          information or Personal Identifiable Information (PII) is processed, stored, transmitted or received by
          Private Identity LLC.
        </p>

        <h3>DO YOU CONSENT TO ENROLL?</h3>
        <div className="buttons">
          <img alt="cancel" src={cancelIcon} onClick={disagreeOnTerms} />
          <img alt="validate" src={validIcon} onClick={agreeOnTerms} />
        </div>
      </div>
    </div>
  </Modal>
);

ConsentModal.propTypes = {
  modalIsOpen: PropTypes.bool,
  disagreeOnTerms: PropTypes.func,
  agreeOnTerms: PropTypes.func,
};

export default ConsentModal;
