import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardActions, CardContent, Divider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import LoadingSpinner from '../../components/LoadingSpinner';
import DeleteAccIcon from '../../assets/account-delete.png';
import useStyles from './style';
import useDelete from '../../hooks/useDelete';

const StyledCard = withStyles({
  root: {
    padding: '12px 12px 0',
    boxShadow: '0px 2px 4px 4px rgb(159 162 191 / 18%), 0px 2px 6px 2px rgb(159 162 191 / 32%);',
    height: '100%',
  },
})(Card);

const DeleteAccount = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const onDeleteEnd = (status) => {
    if (status === 0) {
      navigate('/');
    } else {
      enqueueSnackbar('Failed to delete your account', { variant: 'error' });
    }
    setLoading(false);
  };

  const { onDeleteUser } = useDelete(onDeleteEnd);

  const handleDelete = async () => {
    setLoading(true);
    onDeleteUser();
  };

  return (
    <StyledCard>
      <CardContent className={classes.cardContent}>
        <Box flex="1 0 auto">
          <Typography component="h5" variant="h5">
            Delete Your Account
          </Typography>
          <Typography variant="subtitle2">Remove your account and delete all associated information.</Typography>
        </Box>
        <Box>
          <img className={classes.cardImg} src={DeleteAccIcon} alt="" />
        </Box>
      </CardContent>
      <Divider />
      <CardActions disableSpacing className={classes.createKey}>
        <Button color="secondary" variant="contained" size="large" onClick={handleDelete}>
          Delete Account
        </Button>
      </CardActions>
      {loading && <LoadingSpinner />}
    </StyledCard>
  );
};

export default DeleteAccount;
