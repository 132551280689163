import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 120,
    minHeight: 'calc(100vh - 259px)',
  },
  card: {
    margin: '24px 0',
    padding: 24,
    boxShadow: '0px 2px 4px 4px rgb(159 162 191 / 18%), 0px 2px 6px 2px rgb(159 162 191 / 32%);',
  },
  title: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  header: {
    marginBottom: 8,
    fontWeight: 'bold',
  },
  subHeader: {
    marginBottom: 24,
  },
  keyForm: {
    padding: '24px 32px',
  },
  label: {
    width: 84,
  },
  iconBtn: {
    marginLeft: 12,
  },
  backImg: {
    height: '50vh',
    paddingLeft: 48,
  },
}));

export default useStyles;
