import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { openCamera } from '@privateid/cryptonets-web-sdk';
import { useEffect } from 'react';

const HintModal = ({ mediaStream, predictUser, setIsOpen, message }) => {
  const predict = async () => {
    setTimeout(async () => {
      await openCamera('userVideo');
      predictUser();
    }, 0);
  };

  useEffect(() => {
    predict();
  }, []);

  return (
    <Modal className="modal" overlayClassName="overlay" open onClose={() => setIsOpen(false)}>
      <div className="modal-container">
        <div className="modal-content">
          <video muted className="face" id="userVideo" height={1280} width={720} autoPlay playsInline />
          <p>{message}</p>
        </div>
      </div>
    </Modal>
  );
};

HintModal.propTypes = {
  setIsOpen: PropTypes.func,
  message: PropTypes.string,
  predictUser: PropTypes.func,
  mediaStream: PropTypes.object,
};

export default HintModal;
