export const wait = async (timeout) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });

export const getSeatId = (uuid) => {
  if (!uuid) return '';
  const id = uuid.match(/\d/g).slice(0, 2);
  id.push(uuid.match(/[a-z]/g)[0]);
  return id.join('');
};

export const getDisplayedMessage = (result) => {
  switch (result) {
    case -1:
      return 'No Face';
    case 0:
      return 'Face detected';
    case 1:
      return 'Image Spoof';
    case 2:
      return 'Video Spoof';
    case 3:
      return 'Video Spoof';
    case 4:
      return 'Too far away';
    case 5:
      return 'Too far to right';
    case 6:
      return 'Too far to left';
    case 7:
      return 'Too far up';
    case 8:
      return 'Too far down';
    case 9:
      return 'Too blurry';
    case 10:
      return 'PLEASE REMOVE EYEGLASSES';
    case 11:
      return 'PLEASE REMOVE FACEMASK';
    default:
      return '';
  }
};
