import { useState } from 'react';
import { openCamera } from '@privateid/cryptonets-web-sdk';
import { useNavigate } from 'react-router';

const useCamera = (element = 'userVideo', facingMode = 'face') => {
  const history = useNavigate();
  // Initialize the state
  const [ready, setReady] = useState(false);
  const [devices, setDevices] = useState(false);
  const [device, setDevice] = useState(false);
  const [faceMode, setFaceMode] = useState(false);
  const [mediaStream, setStream] = useState(false);

  const init = async () => {
    if (ready) return;
    try {
      const { devices, faceMode, stream } = await openCamera(element);
      setFaceMode(faceMode);
      setStream(stream);
      if (devices.length > 0) {
        const options = devices.map((d) => ({
          label: d.label,
          value: d.deviceId,
        }));

        setDevices(options);
        setDevice(options[0]);
      }

      setReady(true);
      return;
    } catch (e) {
      history.push('/no-camera');
    }
  };

  return { ready, init, stream: mediaStream, devices, device, setDevice, faceMode };
};

export default useCamera;
