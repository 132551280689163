import { useState } from 'react';
import { deleteUser } from '@privateid/cryptonets-web-sdk';
import useWasm from './useWasm';

const useDelete = (onDeleteEnd) => {
  const { ready } = useWasm();
  const [loading, setLoading] = useState(false);

  const callback = (result) => {
    setLoading(false);
    onDeleteEnd(result.returnValue.status);
  };

  const onDeleteUser = async () => {
    const userUUID = localStorage.getItem('userUUID');

    if (ready && userUUID) {
      setLoading(true);
      deleteUser(userUUID, callback);
    }
  };

  return { loading, onDeleteUser };
};

export default useDelete;
